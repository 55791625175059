<template>
  <div class="tooltip bg-warning" v-b-tooltip.hover :title="title">
    <i class="fa fa-question"></i>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    title: { type: String, required: true }
  }
};
</script>

<style lang="scss">
.tooltip {
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.15s ease;
  color: #ffffff;
  opacity: 0.9;
  padding: 0px 6px;
  border-radius: 50%;
  z-index: 100;

  i {
    font-size: 9px;
    color: #ffffff;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
