<template>
  <KTCard
    :page="page"
    :isForm="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Título: <span class="text-danger">*</span> </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.titulo"
            placeholder="Digite o título"
            autocomplete="off"
            required=""
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Objetivo: <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-textarea
            size="lg"
            v-model="form.objetivo"
            placeholder="Digite o objetivo"
            rows="5"
            autocomplete="off"
            required=""
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Moeda: <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            :options="form_options.moeda"
            v-model="form.idmoeda"
            required=""
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Atividade: <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTSelect
            :options="form_options.atividade"
            v-model="form.idtipo_plano_investimento"
            required=""
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        :class="{ 'mb-0': !isPP }"
      >
        <template #label>
          Tempo para análise econômica e financeira:
          <span class="text-danger">*</span>
          <Tooltip
            title="Atenção ao escolher o tempo para a análise econômica e financeira. Após salvar a escolha não será possível modificar."
          />
        </template>
        <template #default>
          <b-form-radio-group
            size="lg"
            v-model.number="form.tempo_analise_economica_financeira"
            :options="form_options.analise"
          ></b-form-radio-group>
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
        v-if="isPP"
      >
        <template #label>
          Incidência de impostos, taxas ou tributos sobre a comercialização:
          <span class="text-danger mr-2">*</span>
          <Tooltip
            title="Caso não possua incidência de impostos, taxas ou tributos sobre a comercialização, por favor, informar o valor 0 (zero) neste campo."
          />
        </template>
        <template #default>
          <b-input-group size="lg" append="%">
            <KTInputNumeric
              v-model="form.impostos_sobre_receita"
              :precision="2"
              placeholder="Digite o percentual"
              autocomplete="off"
              required
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import CommonDataService from "@/core/services/commondata.service";
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import Tooltip from "@/view/layout/extras/Tooltip.vue";

export default {
  name: "PipForm",
  props: {
    tipo: {
      type: String,
      required: true,
      validator: (value) => value == "PP" || value == "PN"
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  components: {
    Tooltip
  },
  data() {
    return {
      page_options: {
        pp: {
          icon: "la-briefcase",
          title: "Cadastro de Plano de Investimento Rural",
          description: `Para iniciar a análise econômico-financeira,
            primeiramente cadastre seu Plano de Investimento Rural,
            utilizando as informações que você definiu quando
            desenvolveu sua ideia de negócio.`
        },
        pn: {
          icon: "la-industry",
          title: "Cadastro de Plano de Negócios",
          description: `Para iniciar a análise econômico-financeira, primeiramente
            cadastre seu Plano de Negócios, utilizando as informações que você
            definiu quando desenvolveu sua ideia de negócio.`
        }
      },
      form: {},
      form_options: {
        atividade: [],
        moeda: [],
        analise: [
          { text: "5 anos", value: 5 },
          { text: "6 anos", value: 6 },
          { text: "7 anos", value: 7 },
          { text: "8 anos", value: 8 },
          { text: "9 anos", value: 9 },
          { text: "10 anos", value: 10 }
        ]
      },
      apiResource: "pip",
      parentRoute: { name: "inicio" }
    };
  },
  computed: {
    isPP() {
      return this.tipo == "PP";
    },
    tipo_plano_investimento() {
      return this.isPP ? 1 : 2;
    },
    page() {
      return this.isPP ? this.page_options.pp : this.page_options.pn;
    },
    manual() {
      return {
        session: "pip_form",
        tipo: this.tipo_plano_investimento
      };
    }
  },
  methods: {
    onSubmit() {
      const route = (data) => ({
        name: "pip_ver",
        params: { idplano_investimento: data.idplano_investimento }
      });

      this.ApiSubmitAdd(
        {
          ...this.form,
          tipo_plano_investimento: this.tipo_plano_investimento
        },
        route
      );
    },
    getPageData() {
      const requests = [
        this.Api.GetData(),
        CommonDataService.getTipoPlanoInvestimento(
          this.tipo_plano_investimento
        ),
        CommonDataService.getMoeda()
      ];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_ATIVIDADE = 1;
        const R_MOEDA = 2;

        this.form = res[R_GETDATA].plano ?? {};
        this.form_options.atividade = res[R_ATIVIDADE];
        this.form_options.moeda = res[R_MOEDA];
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
    this.initManual(this.manual.session, this.manual.tipo);
  }
};
</script>
